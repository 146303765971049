.image_upload {
  font-family: sans-serif;
  /* text-align: center; */
  background-color: #f6f9fe;
  margin: 0px 29% 20px 41%;
  width: 25%;
  padding: 20px;
}

.image_div {
    float: left;
    margin: 10px;
}

.image-item {
  display: inline-block;
}

input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
}

/* turns image to grayscale when hovered over */
/* hints to the user that they can remove the image by clicking it */
.uploaded_image {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
}
.uploaded_image:hover {
  height: 100px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
}
.uploaded_image {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}
